.picture-viewer {

    cursor: pointer;
    width: calc(100% / 6);

    .picture {
        width:100%;
        overflow:hidden;
        border-radius:10px;
        position:relative;
        box-shadow: 0 0 10px rgba(0,0,0,0.05);
        background:#cdd8e2;

        img {
            height:100%;
            width:100%;
        }

        .overlay {
            position:absolute;
            opacity: 0;
            width:100%;
            height:100%;
            background:rgba(0,0,0,0.2);
            transition: opacity .1s;

            svg {
                color: white;
                font-size: 4rem;
            }
        }
    }

    &:hover {
        .overlay {
            opacity: 1;
        }
    }
}
