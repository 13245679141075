.login-page {

    width:100%;
    height: 100vh;

    @keyframes logo {
        from { opacity: 0.5; transform: scale(0); }
        to { opacity: 1; transform: scale(1); }
    }

    .logo {
        transform: scale(0);
        animation: logo 0.5s;
        animation-fill-mode: forwards;
        animation-delay: 0.8s;
        position:relative;
        z-index:50;
    }

    .pictures {

        > div {

            position:absolute;

            @for $i from 0 through 14 {

                $rotate: if($i % 3 == 0, $i * (360 / 5) * 1deg, floor($i / 3) * (360 / 5) * 1deg + (360deg / 10));

                @keyframes picture-#{$i} {
                    $translate: if($i % 3 == 0, 75px, if($i % 2 == 0, 58px, 90px));
                    from { transform: translate(0) scale(0); opacity: 1; }
                    to { transform: translate($translate) scale(1); opacity: 1; }
                }

                @keyframes move-#{$i} {
                    from { transform: rotate($rotate); }
                    to { transform: rotate($rotate + 360deg); }
                }

                &.holder-#{$i} {
                    transform: rotate($rotate);
                    animation: move-#{$i};
                    animation-delay: 1s;
                    animation-duration: 250s;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                }

                .picture-#{$i} {
                    opacity:0;
                    animation-fill-mode: forwards;
                    animation-name: picture-#{$i};
                    animation-delay: 1.1s + 0.08s * ($i % 8);
                    animation-duration: 0.8s;
                }
            }
        }
    }

    .bottom {
        margin-top:140px;
        width:100%;
        max-width:235px;
    }

    .form {

        position:relative;
        z-index:10;

        input {
            width:100%;
            border-radius:2px;
            border:1px solid #eee;
            padding:0.5rem 0.8rem;
            background:white;
        }

        button {
            width:100%;
            border: none;
            background: #41abff;
            color:white;
            padding:0.5rem;
            border-radius:2px;
            transition: background .2s;

            &:hover {
                background: #368ed4;
            }
        }

        .split {
            position:relative;

            span {
                background: white;
                padding:0 0.7rem;
            }

            &:before {
                content: "";
                z-index: -1;
                display:block;
                width:100%;
                height:1px;
                background:#eee;
                position:absolute;
                top:10px;
            }
        }
    }
}
