.slideshow {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,0.8);
    z-index:100;

    button, a {
        border:none;
        background: transparent;
        color:white;
        outline: none;
        padding:1rem;
    }

    button {
        font-size:2.5rem;

        &.slider-arrow {
            position:relative;
            z-index: 111;
        }
    }

    a {
        font-size:1.8rem;
        position:relative;
        top:-3px;
    }

    .toolbar {
        position:fixed;
        right:0;
        z-index: 110;
    }

    .slider {

        .slider-arrow {
            position:fixed;
            top:50%;

            &.chevron-right {
                right:0;
            }
        }

        .slick-slide {

            img {

                max-width:100%;
                max-height:90vh;
            }

            .image-holder {

                height: 100vh;
            }

            .pinch-holder {

                height: 100vh;

                > div {
                    margin-top: 25%;
                }
            }
        }
    }
}

.tr-slideshow-enter {
    opacity: 0;
}

.tr-slideshow-enter-active {
    opacity: 1;
    transition: opacity .3s;
}

.tr-slideshow-exit {
    opacity: 1;
}

.tr-slideshow-exit-active {
    opacity: 0;
    transition: opacity .3s;
}
