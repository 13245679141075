.directory-viewer {
    width:100%;
    cursor: pointer;
    outline: none;

    .image {
        width:100%;
        padding-top:100%;
        position:relative;
        overflow:hidden;
        border-radius:10px;
        background: #cdd8e2;

        > div {

            img {
                position:absolute;
                width:100%;
                height:100%;
                top:0;
                transition: opacity .3s;
            }
        }

        .placeholder {
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            background: #eee;
            color:#bbb;

            svg {
                font-size:1.5rem;
            }
        }
    }

    .metas {
        font-weight: 700;
        font-family: 'Lato', sans-serif;
        position: relative;
        color: #788289;

        p {

            &.counter {
                opacity: 0;
                position: absolute;
                top: 0;
                right: 0.2rem;
                padding-left: 4rem;
                background: linear-gradient(to right, transparent, white, white);
                font-weight: 100;
                transition: opacity .3s, right .2s;
            }
        }
    }

    &:hover {
        text-decoration: none;

        .image img {
            opacity: 0.8;
        }

        .metas .counter {
            opacity: 1;
            right: 0;
        }
    }
}
