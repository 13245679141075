.circle-picture {

    border-radius:100px;
    overflow:hidden;
    background:white;
    box-shadow: 0 0 30px rgba(0,0,0,0.05);

    .picture {
        position:relative;

        img {
            position:absolute;
            height:100%;
            min-width:100%;
        }
    }
}
